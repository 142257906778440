import React, { useState } from "react";
import Helmet from 'react-helmet';
import Layout from '../components/layout';
import favicon from '../images/favicon.ico';
import { Slide } from 'react-reveal';
import Wave from 'react-wavify';
import { InPageBot } from "@leadoo/react-bot";


const BOT_CODE = "botxWuWV";

function Webinar() {
    const [chatbot_1, setChatbot_1] = useState(false);

    return(
        <Layout>
            <Helmet>
                <title>Webinar | Crediflow</title>
                <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
                <meta name="description" content="Vi går igenom hur du enkelt bjuder in dina leverantörer i CrossState eVoyce portalen." />
                <meta name="keywords" content="webinar | Automatisera fakturor | DocUp" />
                <meta property="og:title" content="Crediflow AB" />
                <meta property="og:tyope" content="website" />
                <meta property="og:discription" content="Steg för steg hur du som kund hos Crediflow gör för att börja ta emot e-fakturor." />
                <meta property="og:image" content=" " />
                <meta property="og:locale" content="sv_SE" />
                <meta property="og:url" content="https://www.crediflow.se/webinar" />
                <link rel="cannonical" href="https://www.crediflow.se/webinar" />
            </Helmet>

            <section>
                <div className="w-screen bg-white border-t border-gray-100">
                    <div className="container mx-auto px-4 xl:px-24">
                        <div className="grid lg:grid-cols-2 py-24 gap-20">
                            <Slide left>
                                <div className="flex flex-col items-start justify-center">
                                    <h1 className="title-font font-semibold text-3xl text-blue-custome">Senaste webinar</h1>
                                    <p className="leading-relaxed mt-8 text-lg text-gray-600">
                                        Så enkelt är det att ta emot e-faktura med 
                                        Crediflow. Steg för steg hur du som kund hos 
                                        Crediflow gör för att börja ta emot e-fakturor. 
                                        Vi går igenom hur du enkelt bjuder in dina 
                                        leverantörer i CrossState eVoyce portalen. 
                                    </p>
                                    <button onClick={() => setChatbot_1(true)} className="px-10 py-2 rounded-full mt-10 text-white bg-blue-custome shadow-md hover:opacity-90">Kom i kontakt</button>
                                    {chatbot_1 
                                        ? 
                                            <div className="h-full w-full mt-10">
                                                <InPageBot code={BOT_CODE} seamless/>
                                            </div> 
                                        : "" 
                                    }
                                </div>
                            </Slide>
                            <Slide right>
                                <div className="w-full mx-auto">
                                    <iframe 
                                    style={{minHeight: "300px", maxHeight: "350px"}}
                                    className="w-full"
                                    title="youtube-video-webinar"  
                                    src="https://player.vimeo.com/video/526029981"
                                    frameBorder="0" 
                                    allowFullScreen>
                                </iframe>
                                </div>
                            </Slide>
                           

                        </div>
                    </div>
                    <Wave 
                        className="-mb-2"
                        fill='#F3F4F6'
                        paused={false}
                        options={{
                        height: 20,
                        amplitude: 50,
                        speed: 0.15,
                        points: 3
                        }}
                    />
                </div>
            </section>


            <section className="bg-gray-100">
                <div className="container mx-auto px-4 xl:px-24 pb-32">
                    <section className="w-full">
                        <h1 className="text-center text-blue-custome title-font font-semibold text-3xl">Automatisera fakturor &amp; DocUp</h1>
                        <p className="w-full md:w-2/3 xl:w-1/2 mx-auto mt-6 text-center text-gray-600">
                            Automatisera dina fakturor. Smart, enkelt och till 
                            rätt kostnad. Crediflow håller ett webbinar om DocUp 
                            för Edison kunder. Se hur integrationen fungera 
                            och få en överblick i hur DocUp funkar både för 
                            dig som litet bolag eller för dig som har stora 
                            volymer. Självklart ingår e-faktura på ett 
                            enkelt sätt.
                        </p>
                        <div className="w-full lg:w-2/3 xl:w-1/2 mx-auto mt-8">
                            <iframe 
                                style={{minHeight: "350px"}}
                                className="w-full rounded-lg shadow-lg mx-auto"
                                title="youtube-video-webinar"  
                                src="https://www.youtube.com/embed/hmlSZ50Qqyw" 
                                frameBorder="0" 
                                allowFullScreen
                            />
                        </div>
                    </section>
                   

                    <div className="w-full pt-32 pb-24">
                        <hr className="w-full text-gray-200"/>
                    </div>
                    
                    <section className="w-full">
                        <h1 className="text-center text-blue-custome title-font font-semibold text-3xl">CrossState eVoyce</h1>
                        <div className="w-full lg:w-2/3 xl:w-1/2 mx-auto mt-8">
                            <iframe 
                                style={{minHeight: "350px"}}
                                className="w-full rounded-lg shadow-lg mx-auto bg-gray-900"
                                title="youtube-video-webinar"  
                                src="https://player.vimeo.com/video/219352504"
                                frameBorder="0" 
                                allowFullScreen
                            />
                        </div>
                    </section>
                    

                    <div className="w-full pt-32 pb-24">
                        <hr className="w-full text-gray-200"/>
                    </div>

                    <section className="w-full">
                        <h1 className="text-center text-blue-custome title-font font-semibold text-3xl">Crediflow och CRM</h1>
                        <div className="w-full lg:w-2/3 xl:w-1/2 mx-auto mt-8">
                            <iframe 
                                style={{minHeight: "350px"}}
                                className="w-full rounded-lg shadow-lg mx-auto bg-gray-900"
                                title="youtube-video-webinar"  
                                src="https://www.youtube.com/embed/UbGNLPvc3Yw"
                                frameBorder="0" 
                                allowFullScreen
                            />
                        </div>
                    </section>

                </div>
            </section>
        </Layout>
    );
}
export default Webinar;